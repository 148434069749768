import { defineStore } from "pinia";
import { useGlobalStore } from "./store"; // Import the global store

export const useAvatarStore = defineStore("useAvatarStore", {
  state: () => ({
    avatar: {
      selectedHair: {},
      skinTone: "",
      selectedExpression: {},
      selectedFacialHair: {},
      selectedTopClothes: "",
      selectedBottomClothes: "",
      selectedShoes: "",
    },
    topClothesPosition: "108px",
    bottomClothesPosition: "23px",
  }),
  actions: {
    setAvatarValues(av = {}) {
      const isObject = (val) => typeof val === "object";
      const {
        selectedExpression,
        selectedHair,
        selectedFacialHair,
        selectedBottomClothes,
        selectedShoes,
        skinTone,
        selectedTopClothes,
        ...rest
      } = av;
      this.avatar = { ...rest };
      this.avatar.selectedExpression = isObject(selectedExpression)
        ? selectedExpression
        : { style: "facial_expression_3", color: "#FFB176" };
      this.avatar.selectedShoes = isObject(selectedShoes)
        ? selectedShoes
        : { style: "type_1" };
      this.avatar.selectedTopClothes = selectedTopClothes || "clothe_13";
      this.avatar.selectedBottomClothes = isObject(selectedBottomClothes)
        ? selectedBottomClothes
        : { style: "type_1" };
      this.avatar.skinTone = skinTone || "#FFB176";
      this.avatar.selectedHair = isObject(selectedHair)
        ? selectedHair
        : { style: "", color: "" };
      this.avatar.selectedFacialHair = isObject(selectedFacialHair)
        ? selectedFacialHair
        : { style: "", color: "" };
    },
    changeAvatarHairstyle(style, color) {
      if (style === "none") {
        this.avatar.selectedHair = {
          style: "",
          color: "",
        };
      } else {
        this.avatar.selectedHair = {
          style,
          color,
        };
      }
    },

    changeSkinTone(color) {
      this.avatar.skinTone = color;
    },

    changeFacialHair(style, color) {
      if (style === "none") {
        this.avatar.selectedFacialHair = {
          style: "",
          color: "",
        };
      } else {
        this.avatar.selectedFacialHair = {
          style,
          color,
        };
      }
    },
    changeFacialExpression(style, color) {
      if (style === "none") {
        this.avatar.selectedExpression = {
          style: "",
          color: "",
        };
      } else {
        this.avatar.selectedExpression = {
          style,
          color,
        };
      }
    },
    changeClothes(item, color, feature) {
      switch (feature) {
        case "top":
          this.avatar.selectedTopClothes = item;
          break;
        case "bottom":
          this.avatar.selectedBottomClothes = {
            style: item,
            color,
          };
          break;
        case "shoes":
          this.avatar.selectedShoes = {
            style: item,
            color,
          };
          break;
      }
    },
  },
});
